import React, { Component } from 'react'
import Link from 'gatsby-link'
import Img from 'gatsby-image'

export default class card extends Component {
  render() {
    const { title, date, excerpt, path, description, image, tags } = this.props

    const renderTags = tags.map(tag => {
      return <span className=" is-2 column tag is-info">{tag}</span>
    })

    return (
      <div className="column is-4">
        <div className="card is-shady">
          <Link to={path} className="block">
            <div className="card-image">
              {this.props.image && <Img fluid={image.childImageSharp.fluid} />}
            </div>
          </Link>

          <div className="card-content">
            <div className="content">
              <h4>{title}</h4>
              <p>{date}</p>
              <p>{description}</p>
              <div className="columns">{renderTags}</div>
              <Link
                className="button is-link modal-button"
                to={path}
                data-target="modal-image2"
              >
                Read More
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
