import React from 'react'
import BlogCard from '../components/blog/card'
import Layout from '../layouts/index'
import { graphql } from 'gatsby'
import Footer from '../components/footer'

const Blog = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark
  return (
    <Layout>
      <div className="container">
        <section className="section section-title">
          <div className="container">
            <h1 className="title has-text-centered">Blog</h1>
          </div>
          <hr className="section-title__gap" />
        </section>
        <section>
          <div className="columns blog features is-multiline">
            {posts.map(({ node: post }) => {
              const { frontmatter } = post
              const {
                path,
                title,
                date,
                excerpt,
                description,
                image,
                tags,
              } = frontmatter
              return (
                <BlogCard
                  path={path}
                  title={title}
                  date={date}
                  excerpt={excerpt}
                  image={image}
                  key={path}
                  tags={tags}
                  description={description}
                />
              )
            })}
          </div>
        </section>
      </div>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query IndexQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fields: { collection: { eq: "posts" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            tags
            excerpt
            description
            image {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Blog
